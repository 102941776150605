<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12">
          <b-overlay
              :show="showOver"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>
          <b-button
            class="btn btn-gradient-primary mb-1 mr-50"
            @click="$router.go(-1)"
          >
            <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
          </b-button>
          <b-button
            class="btn btn-gradient-success mb-1 ml-25 mr-50"
            :disabled="isDisabled"
            @click="Submit()"
          >
            <i class="far fa-save mr-50" /> {{ $t('saveData') }}
          </b-button>

          <b-row class="match-height">
            <b-col md="6">

              <div class="card">
                <div class="header-cardx p-2">
                  <h3 class="mb-50">
                    {{ $t('details') }}
                  </h3>
                </div>

                <div class="p-2">
                  <validation-observer ref="addNew">
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          label-for="h-paecelCode"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                          class="mb-50"
                        >
                          <template v-slot:label>
                            <span class="text-danger">*</span> {{ $t('type') }}
                          </template>

                          <div
                            v-for="(item, index) in itempay"
                            :key="index"
                          >
                            <b-form-radio
                              v-model="transport"
                              name="some-radio9"
                              :value="item"
                              :unchecked-value="null"
                              class="custom-control-primary mb-1"
                              @input="Checkitem(transport)"
                            >
                              {{ item.prefix }}
                              <!--  {{ $t('key-57') }} -->
                            </b-form-radio>

                            <!-- <b-form-radio
                              v-model="transport"
                              name="some-radio9"
                              :value="2"
                              :unchecked-value="0"
                              class="custom-control-primary mb-1"
                              @input="Checkitem(transport)"
                            >
                              {{ $t('payAtTaobao') }}
                            </b-form-radio>

                            <b-form-radio
                              v-model="transport"
                              name="some-radio9"
                              :value="3"
                              :unchecked-value="0"
                              class="custom-control-primary"
                              @input="Checkitem(transport)"
                            >
                              {{ $t('key-58') }} 1688
                            </b-form-radio> -->
                          </div>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="transport && items.consign_send"
                        md="12"
                      >
                        <b-form-group
                          label-for="h-paecelCode"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                          class="mb-50"
                        >
                          <template v-slot:label>
                            <span class="text-danger">*</span> {{ $t('taobaoID') }}
                          </template>

                          <b-form-input
                            v-model="items.taobao_username"
                            type="text"
                          />

                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="transport && items.consign_send"
                        md="12"
                      >
                        <b-form-group
                          label-for="h-taobao_password"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                          class="mb-50"
                        >
                          <template v-slot:label>
                            <span class="text-danger">*</span> {{ $t('taobaoPassword') }}
                          </template>

                          <b-form-input
                            v-model="items.taobao_password"
                            type="text"
                          />

                        </b-form-group>

                      </b-col>

                      <b-col md="12">
                        <b-form-group
                          label-for="h-paecelCode"
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                          class="mb-50"
                        >
                          <template v-slot:label>
                            {{ $t('mnemonic') }}
                          </template>
                          <b-form-input
                            v-model="keyword"
                            type="text"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-table
                          :fields="fieldsRate"
                          :items="[General]"
                          show-empty
                        >
                          <template #empty="">
                            <b-table-empty-slot>
                              <div class="text-center my-2">
                                <img
                                  src="@/assets/images/anan-img/svg/doc_empty.svg"
                                  alt="empty"
                                  height="60px"
                                >
                                <p class="text-muted">
                                  No Data
                                </p>
                              </div>
                            </b-table-empty-slot>
                          </template>

                          <template #cell(rate)="data">
                            {{ data.item.rate_import }}
                          </template>
                          <template #cell(min_price)="">
                            0
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </div>
              </div>
            </b-col>

            <b-col md="6">
              <div class="card">
                <div class="header-cardx p-2">
                  <h3 class="mb-50">
                    {{ $t('item') }}
                  </h3>
                </div>

                <div class="p-2">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-for="h-paecelCode"
                        label-cols="6"
                        label-cols-lg="6"
                        label-cols-md="6"
                        class="mb-50"
                      >
                        <template v-slot:label>
                          <p class="font-weight-bolder">
                            {{ $t('systemBalance') }}:
                          </p>
                        </template>
                        <p class="mt-50 ">
                          {{ Commas(Balance.balance) }} ฿
                        </p>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col md="12">
                      <b-form-group
                        label-for="h-paecelCode"
                        label-cols-md="5"
                        class="mb-50"
                      >
                        <template v-slot:label>
                          <p class="font-weight-bolder">
                            ยอดเงินหยวนในระบบ:
                          </p>
                        </template>
                        <p class="mt-50 ">
                          ¥ {{ Balance.balance_china ? Commas(Balance.balance_china): 0 }}
                        </p>
                      </b-form-group>
                    </b-col> -->
                    <validation-observer ref="simpleRules">
                      <b-col md="12">
                        <b-table
                          responsive="sm"
                          :fields="fieldsRelevant"
                          :items="Transaction"
                          show-empty
                        >
                          <template #empty="">
                            <b-table-empty-slot>
                              <div class="text-center my-2">
                                <img
                                  src="@/assets/images/anan-img/svg/doc_empty.svg"
                                  alt="empty"
                                  height="60px"
                                >
                                <p class="text-muted">
                                  No Data
                                </p>
                              </div>
                            </b-table-empty-slot>
                          </template>
                          <template #cell(index)="data">
                            {{ data.index + 1 }}
                          </template>
                          <template #cell(order_id)="data">
                            <validation-provider
                              #default="{ errors }"
                              name="detail"
                              rules="required"
                            >
                              <b-form-input
                                v-model="data.item.order_id"
                                :state="errors.length > 0 ? false:null"
                              />
                            </validation-provider>
                          </template>

                          <template #cell(price)="data">
                            <validation-provider
                              #default="{ errors }"
                              name="item-detail"
                              rules="required"
                            >
                              <b-form-input
                                v-model="data.item.price"
                                type="number"
                                :state="errors.length > 0 || data.item.price == 0? false:null"
                                @input="Checkprice()"
                              />
                            </validation-provider>
                          </template>

                          <template #cell(action)="data">
                            <b-button
                              v-if="isLastPosition(data.index)"
                              class="btn-success btn-sm"
                              @click="addcell()"
                            >
                              +
                            </b-button>
                            <feather-icon
                              v-else
                              icon="TrashIcon"
                              class="text-danger cursor-pointer"
                              @click="removeRow(data.index)"
                            />
                          </template>
                        </b-table>
                        <!-- <b-col /> -->
                        <b-col cols="12">

                          <div class="d-flex justify-content-around align-items-center p-1">
                            <div class="mr-auto">
                              {{ $t('yuanAmount') }} ¥ {{ Commas(priceall) }} x {{ General.rate_import }}
                            </div>
                            <div> {{ Commas(priceall * General.rate_import) }} ฿</div>
                          </div>

                          <div class="d-flex justify-content-around align-items-center p-1">
                            <div class="mr-auto">
                              {{ $t('key-65') }} ( {{ Transaction.length }} x {{ General.service_fee_taobao }} )
                            </div>
                            <div>  {{ Commas(total_ps = (Transaction.length * General.service_fee_taobao)) }} ฿</div>
                          </div>
                          <div class="d-flex justify-content-around align-items-center p-1">
                            <div class="mr-auto">
                              {{ $t('key-66') }}
                            </div>
                            <div>   {{ Commas(total_amount = ((priceall * General.rate_import) + total_ps)) }} ฿ </div>
                          </div>

                          <!-- <b-list-group horizontal="sm">
                              <b-list-group-item style="width:70%;">
                                {{ $t('yuanAmount') }} ¥ {{ Commas(priceall) }} x {{ General.rate_payment }}
                              </b-list-group-item>
                              <b-list-group-item style="width:30%;"> -->
                          <!-- Yuan balance -->
                          <!-- {{ Commas(priceall * General.rate_payment) }} ฿
                              </b-list-group-item>
                            </b-list-group> -->
                          <!-- <b-list-group horizontal="sm">
                              <b-list-group-item style="width:70%;">
                                {{ $t('key-65') }} ( {{ Transaction.length }} x {{ General.service_fee_taobao }} )
                              </b-list-group-item>
                              <b-list-group-item style="width:30%;"> -->
                          <!-- total price server -->
                          <!-- {{ Commas(total_ps = (Transaction.length*General.service_fee_taobao) ) }} ฿
                              </b-list-group-item>
                            </b-list-group> -->
                          <!-- <b-list-group horizontal="sm">
                              <b-list-group-item style="width:70%;"> -->
                          <!-- Total amount -->
                          <!-- {{ $t('key-66') }}
                              </b-list-group-item>
                              <b-list-group-item style="width:30%;">
                                {{ Commas(total_amount = ((priceall * General.rate_payment) + total_ps)) }} ฿
                              </b-list-group-item>
                            </b-list-group> -->
                        </b-col>
                      </b-col>
                    </validation-observer>
                  </b-row>

                </div>
              </div>
            </b-col>
          </b-row>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BListGroup,
  BListGroupItem,
  BRow,
  BTable,
  BOverlay
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SideBar from '../component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  data() {
    return {
      required,
      keyword: null, // วลีช่วยจำ
      y_balance: 0, // ยอดเงินหยวน
      total_ps: 0, // รวมราคาค่าบริการ
      total_amount: 0, // รวมยอดที่ต้องชำระ
      Pricerate: [
        {
          rate: '¥1 = 5.14 ฿',
          min_price: '¥ 0.00',
        },
      ],
      Transaction: [
        {
          order_id: '',
          price: 0,
          status: 1,
        },
      ],
      index: 0,
      transport: 0,
      taobao_username: null,
      taobao_password: null,
      Balance: [],
      priceall: 0,
      General: [],
      items: null,
      itempay: [],
      isDisabled: false,
      showOver: false, 
    }
  },
  computed: {
    fieldsRate() {
      return [
        {
          key: 'rate', label: this.$t('exchangeRate'), thStyle: { width: '50%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'min_price', label: this.$t('threshold'), thStyle: { width: '50%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },

    fieldsRelevant() {
      return [
        {
          key: 'index', label: this.$t('sequence'), thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'order_id', label: this.$t('key-22'), thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'price', label: `${this.$t('price')}¥`, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.GetData()
    this.getBalance()
    this.getGeneral()
  },
  methods: {
    isLastPosition(data) {
      return data === this.Transaction.length - 1
    },
    async GetData() {
      try {
        const { data: res } = await this.$http.get('alipay/alipay_active')
        if (res.status) {
          this.itempay = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getBalance() {
      this.showOver = true
      try {
        const { data: res } = await this.$http.get('/Balance/getbalanceOne')
        if (res) {
          this.Balance = res
          this.showOver = false
        }
      
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    async getGeneral() {
      try {
        const { data: res } = await this.$http.get('/general/getData')
        if (res.status) {
          this.General = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    Submit() {
      this.showOver = true
      this.isDisabled = true
      const index = this.Transaction.findIndex(items => items.price === 0)
      if (!this.items) {
        this.isDisabled = false
        this.showOver = false
        return this.SwalError('กรุณาเลือกประเภท')
      } if (this.items.consign_send && ((!this.items.taobao_username || !this.items.taobao_password))) {
        this.isDisabled = false
        this.showOver = false
        return this.SwalError('จำเป็นต้องกรอกไอดีเถาเปาและรหัสผ่านเถาเปา')
      } if (this.Balance.balance < this.total_amount) {
        this.isDisabled = false
        this.showOver = false
        return this.SwalError('จำนวนเงินไม่เพียงพอ')
      } if (index >= 0) {
        this.isDisabled = false
        this.showOver = false
        return this.SwalError('กรอกข้อมูลให้ครบถ้วน')
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const obj = {
            payment_type: this.items,
            products: this.Transaction,
            rate: this.General.rate_import,
            rate_service: this.General.service_fee_taobao,
            pay_products: this.total_amount,
            yuan_balance: this.priceall,
            keyword: this.keyword,
          }
          this.$http.post('/Payforchina/StoreData', obj)
            .then(response => {
              if (response.data.status) {
                this.$router.go(-1)
                this.Success(this.$t('key-260'))
                this.showOver = false
              }
            })
            .catch(err => {
              console.log(err)
              this.isDisabled = false
              this.showOver = false
              this.SwalError(err.response.data.data)
            })
        } else {
          this.isDisabled = false
          this.showOver = false
        }
      })

      // if (this.transport === 0) {
      //   this.SwalError('กรุณาเลือกประเภท')
      // } else if (this.transport !== 1) {
      //   if (!this.taobao_username) {
      //     this.SwalError('จำเป็นต้องกรอกไอดีเถาเปา')
      //   } else if (!this.taobao_password) {
      //     this.SwalError('จำเป็นต้องกรอกรหัสผ่านเถาเปา')
      //   } else {
      //     console.log('ffs')
      //   }
      // } else {
      //   console.log('ff')
      // }
    },
    Checkitem(data) {
      if (data) {
        this.items = {
          type_id: data.id,
          consign_send: data.consign_send,
          type_name: data.prefix,
        }
      } else {
        this.items = {
          type_id: null,
          consign_send: null,
          type_name: null,
        }
      }
    },
    Checkvalue() {

    },
    Checkprice() {
      this.priceall = this.Transaction.reduce((total, acc) => total + Number(acc.price), 0)
      // if (this.priceall <= (this.Balance.balance_china ? this.Balance.balance_china : 0)) {
      //   // const sum = this.Balance.balance_china - this.priceall
      //   this.y_balance = 0
      // }
      // if (this.priceall > (this.Balance.balance_china ? this.Balance.balance_china : 0)) {
      //   this.y_balance = (this.priceall - (this.Balance.balance_china ? this.Balance.balance_china : 0)) * this.General.rate_payment
      // }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    addcell() {
      const newRow = {
        order_id: '', price: 0, status: 1,
      }
      this.Transaction.push(newRow)
    },
    removeRow(index) {
      this.Transaction.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {

  .table-mobile {
    width: 1000px !important;
  }
}
</style>
